import React from "react";
import styled from "styled-components";
// Assets
// import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import hardware from '../../assets/img/hardware.png';
import software from '../../assets/img/software.png';
import ecom from '../../assets/img/ecom.png';

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "hardware":
      getIcon = <img style={{}} height={65} src={hardware} alt="hardware"/>;
      break;
    case "software":
      getIcon = <img style={{}} height={65} src={software} alt="hardware"/>;
      break;
    case "ecom":
      getIcon = <img style={{}} height={65} src={ecom} />;
      break;
    default:
      getIcon = <img style={{}} height={65} src={hardware} alt="hardware" />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font16">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 30px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  font-weight: 500;
  text-align: justify;
`;