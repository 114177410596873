import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/integrated_learning.png";
import ProjectImg2 from "../../assets/img/in-house-development.png";
import ProjectImg3 from "../../assets/img/accessible-education.png";
import ProjectImg4 from "../../assets/img/future-proof.png";
import AddImage2 from "../../assets/img/aboutUs.png";
import { useNavigate } from "react-router-dom";

export default function Projects() {
  const navigate = useNavigate();

  function goToSim() {
    navigate("/sim");
  }

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What We Offer?</h1>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <div style={{ marginBottom: "150px" }} className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Integrated Learning"
                text=" We combine software and hardware to provide a seamless educational experience that bridges theoretical and practical learning."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="In House Development"
                text="All our products are designed and manufactured in-house, ensuring high quality and the ability to quickly respond to educational needs."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Accessible Education"
                text="Our tools are affordable and user-friendly, making STEM education accessible to a wide audience, including schools and individual learners"
                action={() => alert("clicked")}
              />
            </div>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img={ProjectImg4}
                  title="Future-Proof Skills"
                  text="Our solutions prepare students for future careers in STEM fields, equipping them with the skills needed to succeed in a technology-driven world."
                  action={() => alert("clicked")}
                />
              </div>
            </div>
          </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div> */}
        </div>
      </div>
      <div style={{ marginTop: "60px" }} className="coralBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="aboutus" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              {/* <h4 className="font15 semiBold">A few words about company</h4> */}
              <h2 className="font40 extraBold">Our Mission</h2>
              <p
                style={{ textAlign: "justify", lineHeight: "1.5rem" }}
                className="font16"
              >
                Tinywires Technologies LLP is dedicated to enhancing STEM
                education through innovative software and hardware solutions.
                Our advanced simulators for robotics and electronics, along with
                our hands-on STEM educational kits, provide students and
                educators with the tools they need to succeed. We aim to bridge
                the gap between theoretical learning and practical application,
                fostering a deeper understanding and interest in STEM fields.
                Our products are designed for schools, colleges, and individual
                learners, accessible through our user-friendly e-commerce
                platform.
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                {/* <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={goToSim} />
                </div> */}
                {/* <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton
                    title="Contact Us"
                    action={() =>{}}
                    border
                  />
                </div> */}
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin: 60px 0;
  text-align: center;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
