import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import './App.css';
import Login from './screens/login/Login.js';
import Signup from './screens/signup/Signup.js';
import Error from './screens/error/Error';
import Home from './screens/home/Home';
import Sim from './screens/sim2/Sim.jsx';
import Landing from './screens/Landing.jsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/*' element={<Error/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/sim' element={<Sim/>}/>
        <Route path="/" element={<Landing/>}/>
      </Routes>
    </Router>
    
  );
}

export default App;
