import React, { useEffect } from 'react';
import { useState } from 'react';
import { ImCircleRight } from 'react-icons/im';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { GoogleGenerativeAI } from '@google/generative-ai';
import styles from './chatbot.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Buffer } from 'buffer';
import { IoSend } from "react-icons/io5";

const genAI = new GoogleGenerativeAI('AIzaSyCr9rZ1o9QDOcivyN4TSU-MwLoEW93C_zs');
const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

function ChatBot({code, takeImage}) {
    const [prompt, setPrompt] = useState('');
    const [result, setResult] = useState('Hi Welcome to Tinywire Labs Sim');
    const [isOpen, setIsOpen] = useState(true);
    const [loading, setLoading] = useState(false);

    const toggleChatbox = () => {
        setIsOpen(!isOpen);
    };

    async function handleSubmit() {
        const data = await takeImage();
        const image = {
            inlineData : {
                data: data,
                mimeType: "image/png",
            }
        }
        setLoading(true);
        setPrompt('');
        const initial = 'I am learning about designing and implementing circuits in arduino using a simulating software. I will provide you code snippets and images of circuits. Please help me in learning and resolving issues. If anyone asks you about your owner or manager or creator or trained or similar like these. Say i am there to help you. I have been integrated by Tiny Wires.\n';
        const codeSection = `\`\`\` ${code} \`\`\``;
        const result = await model.generateContent([initial + prompt + codeSection, image]);
        const response = await result.response;
        const text = response.text;
        setResult(text);
        setLoading(false);
    }

    async function handleKeyEnter(e){
        if(e.key === 'Enter'){
            handleSubmit();
        }
    }

    return (
        <div>
            <div className={styles.chatbotIcon} onClick={toggleChatbox}>
                <img src="https://img.freepik.com/free-vector/chatbot-chat-message-vectorart_78370-4104.jpg" alt="Chatbot" />
            </div>
            {isOpen && (
                <div className={styles.chatbox}>
                    <div className={styles.chatboxHeader}>
                        <h3>Chatbot</h3>
                        <button onClick={toggleChatbox}>X</button>
                    </div>
                    <div className={styles.chatboxContent}>
                        <ReactMarkdown
                            children={result}
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || '')
                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            children={String(children).replace(/\n$/, '')}
                                            style={vscDarkPlus}
                                            language={match[1]}
                                            PreTag="div"
                                            {...props}
                                        />
                                    ) : (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    )
                                }
                            }}
                        />
                    </div>
                    <div 
                        id={'input'} 
                        className={styles.chatboxInput}
                        onKeyDown={handleKeyEnter}
                    >
                        <input 
                            type="text" 
                            placeholder="Type a message..." 
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}/>
                        <div className="input-btn" onClick={handleSubmit}>
                            {
                                loading ?  
                                    <CircularProgress color= 'primary' size={24} /> :
                                    <IoSend />
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChatBot;
