import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <p className="font16 semiBold">
              Invest in your education with our affordable pricing options. Get
              exceptional value for your money with our flexible plans.
              <br />
              Tailored pricing plans to fit your specific needs and budget.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="Free"
                title="Basic"
                text="Get hands on experience with simulation."
                offers={[
                  { name: "Simulation Software", cheked: true },
                  { name: "Limited Components", cheked: true },
                  { name: "Components Library", cheked: false },
                  { name: "AI Chatbot", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="₹99/mo"
                title="Standard"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Simulation Software", cheked: true },
                  { name: "Limited Components", cheked: true },
                  { name: "Components Library", cheked: true },
                  { name: "AI Chatbot", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="₹199/mo"
                title="Advance"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Simulation Software", cheked: true },
                  { name: "Unlimited Components", cheked: true },
                  { name: "Components Library", cheked: true },
                  { name: "AI Chatbot", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
