import React, { useEffect, useRef, useState } from "react";
import styles from "./Sim.module.css";
import "@wokwi/elements";
import { parse } from "intel-hex";
import { FaLaptopCode } from "react-icons/fa6";
import { SiArduino } from "react-icons/si";
import { FaPlay } from "react-icons/fa";
import { RxAvatar } from "react-icons/rx";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  CPU,
  avrInstruction,
  AVRIOPort,
  portDConfig,
  PinState,
  AVRTimer,
  timer0Config,
} from "avr8js";
import Draggable from "react-draggable";
import Header from "../../components/header/Header";
import ArduinoEditor from "../../components/ArduinoEditor";
import ChatBot from "../../components/chatbot/chatbot2";
import html2canvas from "html2canvas";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import { Resizable } from "react-resizable";

const ItemTypes = {
  COMPONENT: "component",
};

const initialArduinoCode = `
void setup() {
  pinMode(7, OUTPUT); 
}

void loop() {
  digitalWrite(7, HIGH);  
  delay(1000);
  digitalWrite(7, LOW);
  delay(1000);
}
`;

function DraggableComponent({ type, children }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COMPONENT,
    item: { type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={styles.dragItem}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {children}
    </div>
  );
}

function DropZone({
  onDrop,
  components,
  handleContextMenu,
  handleDelete,
  contextMenu,
  ledState,
}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.COMPONENT,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const renderComponent = (type) => {
    if (type === "wokwi-led") {
      return <wokwi-led color="red" />;
    } else if (type === "wokwi-pushbutton") {
      return <wokwi-pushbutton />;
    } else if (type === "wokwi-potentiometer") {
      return <wokwi-potentiometer />;
    } else if (type === "wokwi-arduino-uno") {
      return <wokwi-arduino-uno/>;
    } else if (type === "wokwi-arduino-nano") {
      return <wokwi-arduino-nano />;
    } else if (type === "wokwi-arduino-mega") {
      return <wokwi-arduino-mega />;
    }
  };

  return (
    <div
      ref={drop}
      className={styles.dragArea}
      style={{
        backgroundColor: isOver ? "white" : "white",
      }}
    >
      {components.map((component) => (
        <Draggable key={component.id}>
          <div
            className={styles.componentContainer}
            onContextMenu={(e) => handleContextMenu(e, component.id)}
          >
            {component.type === "wokwi-led" ? (
              <wokwi-led color="red" value={ledState ? true : ""} />
            ) : (
              renderComponent(component.type)
            )}
          </div>
        </Draggable>
      ))}
      {contextMenu.visible && (
        <div
          className={styles.contextMenu}
          style={{ top: contextMenu.y, left: contextMenu.x }}
        >
          <button onClick={() => handleDelete(contextMenu.id)}>Delete</button>
        </div>
      )}
    </div>
  );
}

const Sim = () => {
  const [ledState, setLedState] = useState(false);
  const [arduinoCode, setArduinocode] = useState(initialArduinoCode);
  const [components, setComponents] = useState([]);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    id: null,
  });
  const [board, setBoard] = useState(false);
  const [boardIndex, setBoardIndex] = useState(0);
  const [componentIndex, setComponentIndex] = useState(0);
  const [consoleText, setConsoleText] = useState("----");
  const [isCodeSectionOpen, setIsCodeSectionOpen] = useState(false);
  const [isComponentSectionOpen, setIsComponentSectionOpen] = useState(false);
  const [runCodeState, setRunCodeState] = useState(false);
  const [codeWidth, setCodeWidth] = useState(500); // Initial width of code section
  const [componentWidth, setComponentWidth] = useState(500);
  const printRef = useRef();
  const navigate = useNavigate();

  const boards = [];
  const componentTypes = [
    "wokwi-led",
    "wokwi-pushbutton",
    "wokwi-potentiometer",
    "wokwi-arduino-uno",
    "wokwi-arduino-nano",
    "wokwi-arduino-mega",
  ];

  const runCode = async () => {
    setConsoleText("Running.....");
    const result = await fetch("https://hexi.wokwi.com/build", {
      method: "post",
      body: JSON.stringify({ sketch: arduinoCode }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { hex, stderr } = await result.json();
    if (!hex) {
      setConsoleText(stderr);
      return;
    }

    const { data } = parse(hex);
    const progData = new Uint8Array(data);
    setConsoleText("Code Compiled Successfully");

    const cpu = new CPU(new Uint16Array(progData.buffer));

    const port = new AVRIOPort(cpu, portDConfig);
    port.addListener(() => {
      const turnOn = port.pinState(7) === PinState.High;
      setLedState(turnOn);
    });

    const timer = new AVRTimer(cpu, timer0Config);

    while (true) {
      for (let i = 0; i < 500000; i++) {
        avrInstruction(cpu);
        timer.tick();
      }
      await new Promise((resolve) => setTimeout(resolve));
    }
  };

  const handleDrop = (item) => {
    setComponents((prev) => [...prev, { ...item, id: Date.now() }]);
  };

  const handleDelete = (id) => {
    setComponents((prev) => prev.filter((component) => component.id !== id));
    setContextMenu({ visible: false, x: 0, y: 0, id: null });
  };

  const handleContextMenu = (event, id) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      id: id,
    });
  };

  const handleClickOutside = () => {
    setContextMenu({ visible: false, x: 0, y: 0, id: null });
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const renderComponent = (type) => {
    if (type === "wokwi-led") {
      return <wokwi-led color="red" />;
    } else if (type === "wokwi-pushbutton") {
      return <wokwi-pushbutton />;
    } else if (type === "wokwi-potentiometer") {
      return <wokwi-potentiometer />;
    } else if (type === "wokwi-arduino-uno") {
      return <wokwi-arduino-uno />;
    } else if (type === "wokwi-arduino-nano") {
      return <wokwi-arduino-nano />;
    } else if (type === "wokwi-arduino-mega") {
      return <wokwi-arduino-mega />;
    }
  };

  const toggleComponent = (component) => {
    if (!component) {
      if (isCodeSectionOpen) {
        setIsCodeSectionOpen(false);
      }
      setIsComponentSectionOpen(true);
    } else {
      setIsComponentSectionOpen(false);
    }
  };

  const toggleCode = (code) => {
    if (!code) {
      if (isComponentSectionOpen) {
        setIsComponentSectionOpen(false);
      }
      setIsCodeSectionOpen(true);
    } else {
      setIsCodeSectionOpen(false);
    }
  };

  const takeImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
    });
    const dataURL = canvas.toDataURL("image/png");
    const base64Data = dataURL.replace(/^data:image\/png;base64,/, "");
    return base64Data;
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startCodeWidth = codeWidth;
    const startComponentWidth = componentWidth;

    const handleMouseMove = (e) => {
      const deltaX = e.clientX - startX;
      setCodeWidth(startCodeWidth + deltaX);
      setComponentWidth(startComponentWidth - deltaX);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div className={styles.container}>
      <DndProvider backend={HTML5Backend}>
        <header className={styles.header}>
          <div
            onClick={() => {
              navigate("/");
            }}
            className={styles.headerIcon}
          >
            <img src="icon.png" alt="icon" />
          </div>
          <div className={styles.title}>TINYWIRE LABS</div>
          <div>
            <RxAvatar size={32} />
          </div>
        </header>
        <div className={styles.toolBar}>
          <div
            className={styles.button}
            onClick={() => toggleCode(isCodeSectionOpen)}
          >
            <FaLaptopCode size={24} />
            <div>Code</div>
          </div>
          <div
            className={styles.button}
            onClick={() => toggleComponent(isComponentSectionOpen)}
          >
            <SiArduino size={24} />
            <div>Components</div>
          </div>
        </div>
        <div ref={printRef} className={styles.main}>
          {isCodeSectionOpen && (
            <div className={`${styles.codeSection} ${
              isCodeSectionOpen ? styles.open : ""
            }`} style={{ width: codeWidth }}>
              <div className={styles.editor}>
                <div className={styles.codeHeading}>
                  <div className={styles.codeContainer}>
                    <div className={styles.codeHeader}>Code</div>
                  </div>
                  <div className={styles.runBtn} onClick={runCode}>
                    <FaPlay />
                    <div>Run Simulation</div>
                  </div>
                </div>
                <ArduinoEditor
                  arduinoCode={arduinoCode}
                  setArduinoCode={setArduinocode}
                />
              </div>
              <div className={styles.console}>
                <div className={styles.consoleHeader}>Console</div>
                <div className={styles.consoleOutput}>{consoleText}</div>
              </div>
            </div>
          )}
          <div
            className={styles.resizeHandle}
            onMouseDown={handleMouseDown}
          ></div>
          <DropZone
            onDrop={handleDrop}
            components={components}
            handleContextMenu={handleContextMenu}
            handleDelete={handleDelete}
            contextMenu={contextMenu}
            ledState={ledState}
          />
          <div
            className={styles.resizeHandle}
            onMouseDown={handleMouseDown}
          ></div>
          {isComponentSectionOpen && (
            <div
              className={`${styles.componentSection} ${
                isComponentSectionOpen ? styles.open : ""
              }`}
              style={{ width: componentWidth }}
            >
              <div className={styles.components}>
                {componentTypes.map((type) => (
                  <div key={type} className={styles.componentItem}>
                    <div className={styles.componentName}>
                      {type.replace("wokwi-", "").replace("-", " ")}
                    </div>
                    <DraggableComponent type={type}>
                      {renderComponent(type)}
                    </DraggableComponent>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles.chatbot}>
          <ChatBot code={arduinoCode} takeImage={takeImage} />
        </div>
      </DndProvider>
    </div>
  );
};

export default Sim;
