import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I purchased a tinywires STEM kit for my child and couldn't be happier. The quality of the components is excellent, and the instructions are clear and easy to follow. My child has developed a keen interest in electronics and programming. The online store is well-organized and offers a variety of products."
            author="Vinit"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Tinywires has been a game-changer for our robotics club! The software is incredibly intuitive, making complex concepts accessible to students of all levels. Coupled with the robust hardware kits, we've been able to create some truly impressive projects. The online platform is also a huge plus for managing orders and accessing resources."
            author="Lokesh Kumar"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="As a teacher, I appreciate the focus on practical learning that tinywires offers. The integration of software and hardware creates a dynamic learning environment. My students are more engaged and their problem-solving skills have significantly improved. The pricing is also competitive, making it a great option for schools."
            author="Prashant Singh Rana"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
