import React, { useRef, useEffect } from 'react';
import * as monaco from 'monaco-editor';

const ArduinoEditor = ({arduinoCode, setArduinoCode }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      const editor = monaco.editor.create(editorRef.current, {
        value: arduinoCode,
        language: 'cpp',
        theme: 'vs-dark',
        automaticLayout: true,
      });
      
      // Add listener for code change
      editor.onDidChangeModelContent(() => {
        const code = editor.getValue();
        setArduinoCode(code);
      });

      // Add Arduino-specific keywords and functions
      monaco.languages.registerCompletionItemProvider('cpp', {
        provideCompletionItems: () => {
          const suggestions = [
            {
              label: 'digitalWrite',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'digitalWrite(${1:pin}, ${2:HIGH});',
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            },
            {
              label: 'pinMode',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'pinMode(${1:pin}, ${2:OUTPUT});',
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            },
            // Add more Arduino functions and keywords here
          ];
          return { suggestions: suggestions };
        }
      });

      // Dispose the editor when the component is unmounted
      return () => {
        editor.dispose();
      };
    }
  }, []);

  return <div ref={editorRef} style={{ height: '100%' }}></div>;
};

export default ArduinoEditor;